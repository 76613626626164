"use strict"
jQuery(function ($) {
    $(window).on('scroll', function () {
        if ($(this).scrollTop() > 50) {
            $('#back-to-top').fadeIn();
        } else {
            $('#back-to-top').fadeOut();
        }
    });
    // scroll body to 0px on click
    $(document).on('click', '#back-to-top', function () {
        $('body,html').animate({
            scrollTop: 0
        }, 500);
        return false;
    });
});
jQuery(function ($) {
    var current_page = window.location.href;
    $('.navbar-nav li .nav-link').each(function () {
        if ($(this).attr('href') == current_page) {
            $(this).addClass('active-navbar-item');
        }
    })
});